import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import cn from 'classnames';

function _Modal({ children, inline }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* Default Modal button if NO INLINE  */}
      {!inline && (
        <Button variant="primary" onClick={handleShow}>
          Launch modal
        </Button>
      )}
      {/* Modal 'button/link' given inline */}
      {inline && (
        <div className="modal-element-click" onClick={handleShow}>
          {inline.el}
        </div>
      )}
      <Modal
        // dialogClassName="modal-90w modal-mega-container modal-lg"
        show={show}
        onHide={handleClose}
        id="modal-container"
        className={cn({
          is_demo_mod: inline?.el?.props?.id === 'request-demo-nav',
        })}
      >
        <div className="modal-head-section">
          <MdClose className="modal-close-button" onClick={handleClose} />
          {inline?.el?.props?.title ? (
            <header className="header-title-modal">{inline.el.props.title}</header>
          ) : (
            <>
              <br />
              <br />
            </>
          )}
        </div>
        {/* <Modal.Body>{children()}</Modal.Body> */}
        {typeof children === 'function' ? (
          <Modal.Body>{children({handleClose})}</Modal.Body>
        ) : (
          <Modal.Body>{children}</Modal.Body>
        )}
      </Modal>
    </>
  );
}

_Modal.propTypes = {
  children: PropTypes.func.isRequired,
  inline: PropTypes.shape({
    el: PropTypes.element.isRequired,
  }),
};

export default _Modal;
