import * as constants from './constants';
import { getStorageItem } from './helpers/storage';

const ENVIRONMENT = 'DEV';
// const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const isDev = ENVIRONMENT === 'DEV';
const isTest = ENVIRONMENT === 'TEST';
const isStaging = ENVIRONMENT === 'STAGING';
const isProd = ENVIRONMENT === 'PROD';

const isUnknown = !isDev && !isTest && !isStaging && !isProd;

if (isUnknown) {
  console.error(
    'UNKNOWN ENVIRONMENTAL CONFIG: ' + ENVIRONMENT
  );
}
const url = new URL(window.location.href);
const searchParams = url.searchParams;

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;
const is_arbilex_computer = getStorageItem(constants.LOCAL_STORAGE__IS_ARBILEX_COMPUTER);
const is_robot = !!searchParams.get('is_robot');
const segment_ignore_arbilex_traffic = isProd;

const enable_segment = (isDev || isStaging || isProd)
    && (!is_arbilex_computer || !segment_ignore_arbilex_traffic)
    && (!is_robot)
    && !!SEGMENT_KEY;

// VERY DANGEROUS, DO NOT TOUCH WITHOUT CONSULTING ERIC
const AUTO_SEARCH_ARB = false; //SHOULD ONLY EVER BE false OR isDev
const AUTO_SEARCH_ARB_PARAMETERS = { tab: constants.APPT_OUTCOME, name: 'stern'.toLowerCase() };
// VERY DANGEROUS, DO NOT TOUCH WITHOUT CONSULTING ERIC

const ARBILEX__LINKED_ARB = (searchParams.get('arb_id')
  && searchParams.get('version')
  && (url.pathname === constants.ARBITRATOR_URL)) ? {
    arb_id: Number(searchParams.get('arb_id')),
    version: searchParams.get('version'),
  }
  : null;

const PRE_LAUNCH = !isProd;
const LAUNCHED = true;

export default {
  AUTO_SEARCH_ARB,
  AUTO_SEARCH_ARB_PARAMETERS,
  ENVIRONMENT,
  GENERAL_BROWSER_BLACKLIST: ['ie'],
  PORTAL_BROWSER_WHITELIST: ['chrome'],
  PORTAL_BROWSER_MIN_HEIGHT_PX: 668,
  PORTAL_BROWSER_MIN_WIDTH_PX: 768,
  REACT_APP_SEGMENT_IS_LOGGING: enable_segment,
  REACT_APP_SEGMENT_DEBUG: isStaging,
  BROWSER_DIMENSION_WARNING_TIMEOUT_FUNCTION: m => m.add(2, 'month'),
  SUMMARY_BOX_TIMELINE_DATA: false,
  ENABLE_DATA_UPLOAD: !isProd,
  ROLLBAR_ENABLED: !isTest,
  ARBILEX__ENABLE_RIGHT_PLACEHOLDER: LAUNCHED,
  ARBILEX__SEGMENT_KEY: SEGMENT_KEY,
  ARBILEX__ENABLE_COOKIE_WIDGET: enable_segment,
  ARBILEX__LINKED_ARB,
  ARBILEX__USE_AGGREGATE_BREACHES: LAUNCHED,
  ARBILEX__STATUS_IN_SEARCH_BOX: LAUNCHED,
  ARBILEX__ARB_CASE_NUMS: LAUNCHED,
  ARBILEX__OUTCOMES_IN_OUTCOME_SUMMARY_BOXES: LAUNCHED,
  ARBILEX__SOURCED_FACTS: PRE_LAUNCH,
  ROLLBAR_TOKEN: '577e065694dc4ca8b806ccb1dc076271',
  IS_DEV: isDev,
  DISPLAYABLE_SOURCES_IN_DEFAULT_ORDER: ['UNCTAD', 'ICSID', 'PCA', 'ITA'],
  PROCESSOR_VIEW: false,
  ENABLE_ACCOUNT_CREATION: isTest,
  FULL_PAGE_PLATFORM: LAUNCHED
};
