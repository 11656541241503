import settings from '../settings';
import _ from 'lodash';
import * as constants from '../constants';
import { toast } from 'react-toastify';
import { getStorageItem, removeStorageItem } from '../helpers/storage';

const did_consent = () => !!getStorageItem(constants.USER_COOKIE_ACCEPTANCE_OBJECT_KEY);
const get_date_of_consent = () => {
  const raw_date = _.get(getStorageItem(constants.USER_COOKIE_ACCEPTANCE_OBJECT_KEY), 'date_given');
  if (raw_date) {
    return new Date(raw_date);
  }
  return null;
};
const revoke_consent = () => {
  removeStorageItem(constants.USER_COOKIE_ACCEPTANCE_OBJECT_KEY);
  toast.info('Consent removed. Refresh the page and clear cookies to remove existing ones.', {
    autoClose: 7000,
    hideProgressBar: true
  });
};

const should_send_event = () =>
  window.analytics
  && settings.REACT_APP_SEGMENT_IS_LOGGING
  && did_consent();

let segment_was_initialized = false;

const Segment = {
  did_consent,
  get_date_of_consent,
  revoke_consent,
  initialize() {
    if (settings.REACT_APP_SEGMENT_IS_LOGGING
        && settings.ARBILEX__SEGMENT_KEY
        && did_consent()
        && !segment_was_initialized
    ) {
      /* eslint-disable */
      var init_func = function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
      analytics.load(settings.ARBILEX__SEGMENT_KEY);
      analytics.page();
      }};
      init_func();
      segment_was_initialized = true;
      /* eslint-enable */
    }
  },
  /* eslint-disable no-unused-vars */
  page(name, options) {
    /* eslint-enable no-unused-vars */
    if (should_send_event()) {
      window.analytics.page(...arguments);
    }
    if (settings.REACT_APP_SEGMENT_DEBUG) {
      console.log('Segment page "' + arguments[0] + '" Data payload: ' + JSON.stringify(_.get(arguments, '[1]')));
    }
  },
  identify() {
    if (should_send_event()) {
      window.analytics.identify(...arguments);
    }
    if (settings.REACT_APP_SEGMENT_DEBUG) {
      console.log('Segment identify "' + arguments[0] + '" Data payload: ' + JSON.stringify(_.get(arguments, '[1]')));
    }
  },
  track() {
    if (should_send_event()) {
      window.analytics.track(...arguments);
    }
    if (settings.REACT_APP_SEGMENT_DEBUG) {
      console.log('Segment track "' + arguments[0] + '" Data payload: ' + JSON.stringify(_.get(arguments, '[1]')));
    }
  }
};
export default Segment;
