import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

export default class AuthModalContainer extends Component {
  componentDidUpdate = (prevProps) => {
    const { isOpen, clearStatusMsg } = this.props;
    if (clearStatusMsg && isOpen && !prevProps.isOpen) {
      clearStatusMsg();
    }
  };

  render() {
    const {
      isOpen,
      closeModal,
      headerText,
      children,
      statusMsg,
      msgClass = 'text-red',
    } = this.props;

    return (
      <Modal show={isOpen} onHide={closeModal} className="auth-modal-container">
        <Modal.Header>
          <Modal.Title>{headerText}</Modal.Title>
          {closeModal && (
            <MdClose className="modal-close-button" onClick={closeModal} />
          )}
        </Modal.Header>
        <Modal.Body>
          {children}
          {statusMsg ? (
            <div className={`${msgClass} full-margin-top`}>{statusMsg}</div>
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}
