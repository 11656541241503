// APPEND NEW NEWS ARTICLES FROM THE TOP

let news = [
  {
    date: 'December 6, 2023',
    link: 'https://www.linkedin.com/events/legaltech-litfin-howwilltechimp7132763374521565186/comments/',
    title: 'Legal Tech & Litfin: How will tech impact Litigation Finance globally?',
    source: 'Litigation Finance Journal',
  },
  {
    date: 'September 22, 2023',
    link: 'https://2go.iccwbo.org/icc-new-york-conference-on-international-arbitration.html#description',
    title: 'Technology in Arbitration: Your Friend, Not Your Replacement (yet)',
    source: '18th ICC New York Conference on International Arbitration',
  },
  {
    date: 'September 20, 2023',
    link: 'https://www.dooninsights.com/230920-legaltech6',
    title: 'Analytics Applied to Litigation',
    source: 'Doon Insights - Legaltech 6 - Workshop in the Windy City',
  },
  {
    date: 'August 4, 2023',
    link: 'https://www.linkedin.com/pulse/why-we-invested-arbilex-high-stakes-litigation-finance-bharati',
    title: 'Why we invested in Arbilex - a high stakes litigation finance startup',
    source: 'Draper Startup House Ventures',
  },
  {
    date: 'June 29, 2023',
    link: 'https://500.co/theglobalvc/arbilex-founder-spotlight',
    title: 'How ArbiLex is bringing AI to the multi-billion dollar litigation finance industry',
    source: '500 Global',
  },
  {
    date: 'May 2, 2023',
    link: 'https://youtu.be/DCmu0mwLk1k',
    title: 'I See Data People Podcast - Episode 8',
    source: 'I See Data People YouTube Channel',
  },
  {
    date: 'March 28, 2023',
    link: 'https://500.co/theglobalvc/optimism-reigns-at-500-globals-flagship-demo-day',
    title: 'Optimism Reigns at 500 Global’s Flagship Demo Day',
    source: '500 Global',
  },
  {
    date: 'December 8, 2022',
    link: 'https://www.tltfsummit.com/speakers',
    title: 'The Massive FinTech Opportunity Hidden in LegalTech',
    source: 'TLTF Summit',
  },
  {
    date: 'September 30, 2022',
    link: 'https://worldarbitrationupdate.com/event/the-arbitral-process-and-new-technologies-artificial-intelligence-virtual-hearings-block-chain-and-their-influence/',
    title:
      'The Arbitral Process And New Technologies: Artificial Intelligence, Virtual Hearings, Block Chain, And Their Influence',
    source: 'World Arbitration Update',
  },

  {
    date: 'September 28, 2022',
    link: 'https://lf.dealmakersforums.com/',
    title: 'Examining Different Approaches to Quantifying & Pricing Legal Risk',
    source: 'LF Dealmakers Forum',
  },

  {
    date: 'March 30, 2022',
    link: 'https://thepractice.law.harvard.edu/article/data-in-the-court/',
    title: 'Data in the Court',
    source: 'The Practice',
  },

  {
    date: 'January 14, 2022',
    link: 'https://events.asucollegeoflaw.com/schiefelbein/agenda/',
    title: 'Artificial Intelligence in Dispute Resolution',
    source: 'Schiefelbein Global Dispute Resolution Conference',
  },

  {
    date: 'October 2, 2021',
    link: 'http://arbitrationblog.kluwerarbitration.com/2021/10/02/arbinbrief-new-initiative-kicking-off-with-a-group-of-trailblazers/',
    title:
      'ARBinBRIEF: New Initiative Kicking Off With A Group Of Trailblazers',
    source: 'Kluwer Arbitration Blog',
  },

  {
    date: 'April 13, 2021',
    link: 'https://www.globalarbitrationnews.com/event/big-data-and-foreseeability-of-decision-making-in-international-arbitration/',
    title:
      'Big Data and Foreseeability in Decision Making in International Arbitration',
    source: 'DIS40 International Online Conference 2021',
  },

  {
    date: 'January 06, 2021',
    link: 'https://www.bizjournals.com/boston/inno/stories/profiles/2021/01/06/arbilex-startup-isabel-yang-legal-tech-harvard-ai.html',
    title: 'How ArbiLex uses AI to predict the odds of legal cases',
    source: 'BOSTINNO',
  },

  {
    date: 'December 17, 2020',
    link: 'http://arbitrationblog.kluwerarbitration.com/2020/12/17/inaugural-washington-arbitration-week-covid-19-silver-linings-and-prudent-prognostics/',
    title:
      'Washington Arbitration Week Webinar: The Rise of Technology in International Arbitration',
    source: 'Kluwer Arbitration Blog',
  },

  {
    date: 'July 16, 2020',
    link: 'https://youtu.be/peqWhovuHVg',
    title:
      'ICDR Webinar: Artificial Intelligence and the Changing Face of International Arbitration',
    source: 'International Centre for Dispute Resolution',
  },
  {
    date: 'June 8, 2020',
    link: 'https://www.pli.edu/programs/international-arbitration',
    title: 'PLI Webinar: Recent Developments in International Arbitration',
    source: 'Practising Law Institute',
  },

  {
    date: 'February 4, 2020',
    link: 'https://www.forbes.com/sites/frederickdaso/2020/02/04/arbilex-a-harvard-law-school-legal-tech-startup-uses-ai-to-settle-arbitrations',
    title:
      'ArbiLex, A Harvard Law School Legal Tech Startup, Uses AI To Settle Arbitrations',
    source: 'Forbes',
  },

  {
    date: 'December 17, 2019',
    link: 'https://innovationlabs.harvard.edu/about/news/2019-year-in-review/',
    title: 'Harvard Innovation Labs 2019 Year in Review',
    source: 'Harvard Innovation Labs',
  },

  {
    date: 'November 5, 2019',
    link: 'https://www.americaninno.com/boston/bostinno-bytes/exclusive-meet-the-startups-in-the-new-cohort-of-harvard-ilabs-launch-lab-x/',
    title:
      'Exclusive: Meet the Startups in Harvard Innovation Labs’ Launch Lab X',
    source: 'AmericanInno',
  },
  {
    date: 'September 25, 2019',
    link: 'http://arbitrationblog.kluwerarbitration.com/2019/11/16/arbitralwomen-career-paths-in-arbitration/',
    title: 'Arbitral Women Panel: Career Paths in Arbitration',
    source: 'Kluwer Arbitration Blog',
  },
  {
    date: 'September 13, 2019',
    link: 'https://www.litigationfutures.com/news/arbitration-predictions-start-up-trains-sights-on-uk',
    title: 'Arbitration predictions start-up trains sights on UK',
    source: 'Litigation Futures',
  },
  {
    date: 'September 12-13, 2019',
    link: 'https://youtu.be/v7Sk56_YKhk',
    title: 'Harvard Legal Tech Symposium',
    source: 'Harvard Law & Technology Society',
  },
  {
    date: 'August 5, 2019',
    link: 'https://markets.businessinsider.com/news/stocks/launch-of-arbilex-brings-ai-and-predictive-analytics-to-international-arbitration-1028417803#',
    title:
      'Launch of ArbiLex Brings AI and Predictive Analytics to International Arbitration',
    source: 'Business Insider',
  },

  {
    date: 'April 2-3, 2019',
    link: 'https://youtu.be/tVvp2dFMl-o',
    title: 'Harvard Litigation Finance Symposium',
    source: 'Harvard Law School',
  },
  {
    date: 'February 25, 2019',
    link: 'http://aria.law.columbia.edu/the-use-of-new-technologies-in-international-arbitration/',
    title: ' The Use of New Technologies in International Arbitration',
    source: 'American Review of International Arbitration',
  },
  {
    date: 'January 21, 2019',
    link: 'https://www.lexology.com/library/detail.aspx?g=fa0aa71b-8a55-4701-b4c6-95ab94aee4e2',
    title:
      'The Year Ahead – Innovation: A new generation of legal analysis tools is emerging',
    source: 'Lexology',
  },
];

export default news;
