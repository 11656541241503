
// Location Change
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

const createResponseTypes = (actionType) => {
  return {
    BASE: actionType,
    REQUEST: `${actionType}_REQUEST`,
    SUCCESS: `${actionType}_SUCCESS`,
    FAILURE: `${actionType}_FAILURE`
  };
};

// Auth
const LOGIN = createResponseTypes('LOGIN');
export { LOGIN };
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS';
export const LOGOUT = createResponseTypes('LOGOUT');
export const LOG_CONSENT = createResponseTypes('LOG_CONSENT');
export const USER_LOAD = createResponseTypes('USER_LOAD');
export const FORGOT_PASSWORD = createResponseTypes('FORGOT_PASSWORD');
export const CLEAR_FORGOT_PW_ERRORS = 'CLEAR_FORGOT_PW_ERRORS';
export const CHANGE_PASSWORD = createResponseTypes('CHANGE_PASSWORD');
export const CLEAR_CHANGE_PW_MSG = 'CLEAR_CHANGE_PW_MSG';
export const CREATE_ACCOUNT = createResponseTypes('CREATE_ACCOUNT');
export const CLEAR_SIGNUP_ERRORS = 'CLEAR_SIGNUP_ERRORS';

// Arbitrator
export const SET_ON_DECK_ARBITRATOR = 'SET_ON_DECK_ARBITRATOR';
export const SET_SELECTED_ARBITRATOR = 'SET_SELECTED_ARBITRATOR';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const GET_ARBITRATORS = createResponseTypes('GET_ARBITRATORS');
export const GET_ARBITRATOR_STATISTICS = createResponseTypes(
  'GET_ARBITRATOR_STATISTICS'
);

// Case
export const FETCH_NEXT_CASE = createResponseTypes('FETCH_NEXT_CASE');
export const POST_NEW_DATA = createResponseTypes('POST_NEW_DATA');
