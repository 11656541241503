/*eslint no-unused-expressions: 0*/
/*eslint no-undef: 0*/

import { isUndefined } from 'lodash';

// if (isUndefined(process.env.REACT_APP_IS_LOGGING)) {
//   throw new Error('process.env.REACT_APP_IS_LOGGING must be set.');
// }

// if (isUndefined(process.env.REACT_APP_LOGGING_LEVEL)) {
//   throw new Error('process.env.REACT_APP_LOGGING_LEVEL must be set.');
// }

export const LogLevels = {
  LOG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  DEBUG: 5
};

export function log() {
  shouldLog(LogLevels.LOG) && console.log.apply(console, arguments);
}

export function info() {
  shouldLog(LogLevels.INFO) && console.info.apply(console, arguments);
}

export function warn() {
  shouldLog(LogLevels.WARN) && console.warn.apply(console, arguments);
}

export function error() {
  shouldLog(LogLevels.ERROR) && console.error.apply(console, arguments);
}

export function debug() {
  shouldLog(LogLevels.DEBUG) && console.debug.apply(console, arguments);
}

function shouldLog(logLevel) {
  return (
    Boolean(process.env.REACT_APP_IS_LOGGING === 'true')
    && process.env.REACT_APP_LOGGING_LEVEL >= logLevel
  );
}
