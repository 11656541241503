import React from 'react';
import SupportEmail from '../components/layout/SupportEmail';
import _ from 'lodash';
import cn from 'classnames';

export * from './actionTypes';
// export * from './enums';

// Styling constants
export const LINK_COLOR = '#003bbb';

// URL constants
export const LANDING_URL = '/welcome';
export const ARBITRATOR_URL = '/arbitrators';

export const BUG_REPORTING_LINK = 'https://forms.gle/6xXddgpnk9k39KP87';

export const SUBSCRIBER_ONLY_TEXT = () => (
  <div className="premium">
    {' '}
    This feature is only available to premium members. Please contact us at{' '}
    <SupportEmail /> to update your subscription.
  </div>
);

export const ROLES = {
  C_ARB: 'claimant arbitrator',
  R_ARB: 'respondent arbitrator',
  PRES: 'president'
};

// Data Viz constants
// // Outcomes Table constants
export const RESP_PREV_MERITS = 'respondent_prevailed';
export const RESP_PREV_JURIS = 'juris_denied';
export const CLAIM_PREV = 'claimant_prevailed';
export const PREVAILED_CONSTANTS = [
  RESP_PREV_MERITS,
  RESP_PREV_JURIS,
  CLAIM_PREV
];
export const BY_CLAIM = 'by_claimant';
export const BY_RESP = 'by_respondent';
export const AS_TRIB_PRES = 'as_president';
export const APPOINTMENT_BY_CONSTANTS = [BY_CLAIM, BY_RESP, AS_TRIB_PRES];
export const APPOINTMENT_TO_TITLE = {
  [BY_CLAIM]: 'Claimant',
  [BY_RESP]: 'Respondent',
  [AS_TRIB_PRES]: 'Tribunal President'
};

const array_to_dict_with_order = arr =>
  _.mapKeys(_.mapValues(arr, (v, i) => {
    v.sort_index = Number(i);
    return v;
  }), v => v.value);

const APPOINTMENT_TYPE_TRANSPARANCY_FACTOR = 0.7;
//IMPORTANT: ENUM SHARED WITH BACKEND, UPDATE BOTH
export const APPOINTMENTS = array_to_dict_with_order([{
  label: 'by Claimant',
  label_short: 'Claimant',
  value: 'BY_CLAIMANT',
  in_selector: true,
  color: '#4880FF',
  summary_label: 'Claimant Arbitrator',
  replaced_summary_label: 'Claimant Arbitrator (replaced)',
  colorTransparent: 'rgb(72, 128, 255, ' + APPOINTMENT_TYPE_TRANSPARANCY_FACTOR + ')',
  rgbcolor: '72, 128, 255',
  donutlabelheight: '22px'
}, {
  label: 'by Respondent',
  label_short: 'Respondent',
  value: 'BY_RESPONDENT',
  in_selector: true,
  color: '#EB4F58',
  summary_label: 'Respondent Arbitrator',
  replaced_summary_label: 'Respondent Arbitrator (replaced)',
  colorTransparent: 'rgb(235, 79, 88, ' + APPOINTMENT_TYPE_TRANSPARANCY_FACTOR + ')',
  rgbcolor: '235, 79, 88',
  donutlabelheight: '22px'
}, {
  label: 'as President',
  label_short: 'President',
  value: 'AS_PRESIDENT',
  in_selector: true,
  color: '#25CC78',
  summary_label: 'President Arbitrator',
  replaced_summary_label: 'President Arbitrator (replaced)',
  colorTransparent: 'rgb(37, 204, 120, ' + APPOINTMENT_TYPE_TRANSPARANCY_FACTOR + ')',
  rgbcolor: '37, 204, 120',
  donutlabelheight: '22px'
}, {
  label: 'as Unknown',
  label_short: 'Unknown role',
  value: 'AS_UNKNOWN',
  in_selector: false,
  color: '#888888',
  summary_label: 'Arbitrator (Role Unknown)',
  replaced_summary_label: 'Arbitrator (Role Unknown, replaced)',
  colorTransparent: 'rgb(127, 127, 127, ' + APPOINTMENT_TYPE_TRANSPARANCY_FACTOR + ')',
  rgbcolor: '127, 127, 127',
  donutlabelheight: '22px'
}]);

export const ARBITRATOR_KEYS_TO_APPOINTMENTS = {
  claimant_arbitrator: APPOINTMENTS.BY_CLAIMANT,
  respondent_arbitrator: APPOINTMENTS.BY_RESPONDENT,
  president: APPOINTMENTS.AS_PRESIDENT,
  unknown_arbitrators: APPOINTMENTS.AS_UNKNOWN
};
export const ARBITRATOR_KEYS = Object.keys(ARBITRATOR_KEYS_TO_APPOINTMENTS);

export const SELECTABLE_APPOINTMENTS = _.filter(APPOINTMENTS, a => a.in_selector);

export const USER_COOKIE_ACCEPTANCE_OBJECT_KEY = 'ARBILEX__USER_COOKIE_ACCEPTANCE_OBJECT';

//IMPORTANT: ENUM SHARED WITH BACKEND, UPDATE BOTH
export const OUTCOMES = array_to_dict_with_order([{
  label: 'Claimant won at Merit stage',
  value: 'CLAIMANT_WON',
  victor: 'claimant',
  color: '#4880FF',
  publicly_resolved: true,
  show_in_outcomes_ui_always: true,
  donutlabelheight: '22px',
}, {
  label: 'Respondent won at Jurisdiction stage',
  value: 'RESPONDENT_WON_JURIS',
  victor: 'respondent',
  color: '#EB4F58',
  publicly_resolved: true,
  show_in_outcomes_ui_always: true,
  donutlabelheight: '35px',
}, {
  label: 'Respondent won at Merit stage',
  value: 'RESPONDENT_WON_MERIT',
  victor: 'respondent',
  color: '#FFC400',
  publicly_resolved: true,
  show_in_outcomes_ui_always: true,
  donutlabelheight: '35px',
}, {
  label: 'Respondent won (stage unknown)',
  value: 'RESPONDENT_WON',
  victor: 'respondent',
  color: '#EE3993',
  publicly_resolved: true,
  show_in_outcomes_ui_if_present: true,
  donutlabelheight: '35px',
}, {
  label: 'Unknown award issued',
  value: 'NON_PUBLIC_AWARD',
  publicly_resolved: true,
}, {
  label: 'Settled',
  value: 'SETTLED',
  permanently_dead: true,
  publicly_resolved: false,
}, {
  label: 'Discontinued',
  value: 'DISCONTINUED',
  permanently_dead: true,
  publicly_resolved: false,
}, {
  label: 'Unknown conclusion',
  value: 'UNKNOWN_CONCLUSION',
  publicly_resolved: false,
}, {
  label: 'In progress',
  value: 'IN_PROGRESS',
  original_proceedings_still_pending: true,
  publicly_resolved: false,
}]);

export const PUBLICLY_RESOLVED_OUTCOMES = _.omitBy(
  OUTCOMES,
  /* eslint-disable no-unused-vars */
  (value, key) => !value.publicly_resolved
  /* eslint-enable no-unused-vars */
);

export const get_outcomes_to_show_in_outcomes_ui = cases => _.pickBy(
  OUTCOMES,
  (value, key) => value.show_in_outcomes_ui_always
  || ((value.show_in_outcomes_ui_if_present) && _.some(cases, c => c.outcome === key))
);

// // Triple Bar Chart Tabs
export const DAMAGES = 'Damages';
export const COSTS = 'Costs';
export const PRE_AWARD_INTEREST_RATE = 'Pre-award Interest';
export const POST_AWARD_INTEREST_RATE = 'Post-award Interest';

export const LOCAL_STORAGE__IS_ARBILEX_COMPUTER = 'is_arbilex_computer';

// // Triple Bar Chart Tab Value to backend response
export const LABEL_TO_ECONOMICS_HASH = {
  'Damages Claimed': 'damages',
  'Costs Claimed': 'costs',
  'Interest Rate Claimed': 'interest_rate'
};

// // Costs constants
export const PAY_OWN_WAY = 'pay your own way';
export const COSTS_FOLLOW_EVENT = 'costs follow the event';

// // Selected Data Viz Tabs
export const CO_APPT = 'Co-appointments';
export const APPT_OUTCOME = 'Case Outcomes';
export const QUANTUM = 'Quantum Decisions';
export const TIMELINE = 'Timelines';
export const COUNSEL = 'Counsel Appearances';
export const DATA_VIZ_TABS = createTabs([
  {
    label: CO_APPT,
    image_url: '../assets/icons/Coappointment.svg',
  },
  {
    label: APPT_OUTCOME,
    short_label: 'Outcomes',
    image_url: '../assets/icons/Gavel.svg'
  },
  {
    label: QUANTUM,
    short_label: 'Quantum',
    image_url: '../assets/icons/Quantum Decisions.svg',
  },
  {
    label: TIMELINE,
    image_url: '../assets/icons/Timelines.svg'
  },
  {
    label: COUNSEL,
    short_label: 'Counsel',
    image_url: '../assets/icons/Case Details.svg'
  }
]);

// CustomTabBar constants
function createTabs(tabs) {
  const PREMIUM_ONLY_TABS = [
    PRE_AWARD_INTEREST_RATE,
  ];

  return tabs.map(tab => ({
    title: (
      <div>
        {tab.image_url ? (
          <img
            src={tab.image_url}
            alt=""
            className={cn({
              'custom-tab-bar-img': true,
              'long-img': tab.image_long ? true : false
            })}
          />
        ) : (
          <span />
        )}
        <span className="display-medium-only">
          {tab.short_label || tab.label}
        </span>
        <span className="display-large-only">
          {tab.label}
        </span>
        {PREMIUM_ONLY_TABS.includes(tab.label) ? (
          <img
            src="../assets/lock.svg"
            alt=""
            style={{ marginLeft: '0.3rem' }}
          />
        ) : null}
      </div>
    ),
    value: tab.label
  }));
}
export const QUANTUM_TABS = createTabs([
  {
    label: DAMAGES,
    short_label: 'Damages',
    image_url: '../assets/icons/Damages.svg',
  },
  {
    label: PRE_AWARD_INTEREST_RATE,
    short_label: 'Pre-award %',
    image_url: '../assets/icons/Pre Award Interest Cash.svg',
    image_long: true
  },
  {
    label: COSTS,
    short_label: 'Costs',
    image_url: '../assets/icons/Costs.svg'
  },
  { label: POST_AWARD_INTEREST_RATE,
    short_label: 'Post-award %',
    image_url: '../assets/icons/Post Award Interest Cash.svg',
    image_long: true
  },

]);

// createTabs(
//   [DAMAGES, PRE_AWARD_INTEREST_RATE, COSTS, POST_AWARD_INTEREST_RATE].map(
//     label => ({ label })
//   )
// );
