import React from 'react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

const copyTextToClipboard = text => {
  copy(text);
  toast.info(`"${text}" copied to clipboard!`, {
    autoClose: 3000,
    hideProgressBar: true
  });
};

const CopyLink = ({ color, text }) => {
  return (
    <span>
      <button onClick={() => copyTextToClipboard(text)} className="copy-link" style={{
        cursor: 'pointer',
        color: color
      }}>
        {text}
      </button>
    </span>
  );
};

export default CopyLink;
