import React, { Component } from 'react';
// import { connect } from 'react-redux';

class SupportEmail extends Component {
  render() {
    const { account, isAuthenticated, className, color } = this.props;
    let bodyString = '';
    if (isAuthenticated && account) {
      const userEmail = account.get('email');
      bodyString = `&body=%0D%0A%0D%0AMy%20username%20is%20${userEmail}`;
    }

    return (
      <a
        className={className || ''}
        href={`mailto:hello@arbilex.co?subject=ArbiLex%20Inquiry${bodyString}`}
        style={{ color }}
      >
        hello@arbilex.co
      </a>
    );
  }
}

export const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.get('isAuthenticated'),
  account: state.auth.get('user'),
});

export default SupportEmail;
