import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

const BestViewingExperienceModal = ({ handleClose }) => {
  return (
    // <Modal

    //   className="best-viewing-experience-modal-container"
    // >

    <>
      <div className="center-this-bve">
        <div className="image-section-container">
          {/* <img
            className="best-viewing-experience-image"
            src="../assets/Modals/For best viewing experience.svg"
            alt=""
          /> */}
        </div>
        <div className="section-title best-viewing-exp-modal-title">
          For the best viewing experience, please use Chrome on a large enough
          screen preferably a desktop or tablet device.
        </div>
        <div className="section-body best-viewing-exp-modal-body">
          While the analytics platform will work to a certain degree on other
          browsers and platforms, they are currently not directly supported. We
          will be working on porting the website to mobile and other browsers as
          our team scales. If there are compatibilities that are high priority
          please let us know at support@arbilex.co.
        </div>
        <Button className="call-to-action bve-modal-btn" onClick={handleClose}>
          I acknowledge this
        </Button>
      </div>
    </>
  );
};

export default BestViewingExperienceModal;
