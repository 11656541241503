import React, { Component } from 'react';
import cn from 'classnames';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

// import {
//   expressInterest,
//   clearSignupErrors
// } from '../../state/actions';
import AuthModalContainer from '../components/auth/AuthModalContainer';

const DemoRequestModalModal = ({ handleClose }) => {
  const notify = () =>
    toast(
      'Thank you for reaching out. Someone will contact you at the email you have provided shortly.',
      {
        hideProgressBar: true,
        theme: 'dark',
        className: 'single-case-toast',
      }
    );
  const supportAPI = async (name, email, product, message) => {
    let response = await fetch(
      'https://d1i2jq400lm6rf.cloudfront.net/support/',
      {
        method: 'POST',
        contentType: 'application/json',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: name,
          email: email,
          product: product,
          message: message,
        }),
      }
    );
    if (response.ok) {
      notify();
    }
  };

  return (
    <div className="demo-req-modal-container">
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          organization: '',
          position: '',
          primary_juris_of_practice: '',
          email: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.first_name) {
            errors.first_name = 'Name required';
          }
          if (!values.last_name) {
            errors.last_name = 'Name required';
          }
          if (!values.organization) {
            errors.organization = 'Organization required';
          }
          if (!values.position) {
            errors.position = 'Position required';
          }
          if (!values.primary_juris_of_practice) {
            errors.primary_juris_of_practice = 'Area of practice required';
          }
          if (!values.email) {
            errors.email = 'Email required';
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          supportAPI({
            name: `${values?.first_name}, ${values?.last_name}`,
            email: values?.email,
            product: values?.product,
            message: `
            DEMO REQUESTED:
                    Organization:                     ${values?.organization}
                    Position:                         ${values?.position}
                    Primary Jurisdiction of Practice: ${values?.primary_juris_of_practice}
            `,
          });
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form className="client-req-demo-modal-form" onSubmit={handleSubmit}>
            <div className="enter-your-name">
              <div>
                <input
                  className="demo-req-input-label"
                  type="text"
                  name="first_name"
                  placeholder="Enter your first name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="formik-error">
                  {touched?.first_name && errors?.first_name}
                </p>
              </div>
              <div>
                <input
                  className="demo-req-input-label"
                  type="text"
                  name="last_name"
                  placeholder="Enter your last name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className="formik-error">
                  {touched?.last_name && errors?.last_name}
                </p>
              </div>
            </div>
            <input
              type="text"
              className="demo-req-input-label"
              name="organization"
              placeholder="Enter your organization"
              value={values.organization}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p className="formik-error">
              {touched?.organization && errors?.organization}
            </p>
            <input
              className="demo-req-input-label"
              type="text"
              name="position"
              placeholder="Enter your role"
              value={values.position}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p className="formik-error">
              {touched?.position && errors?.position}
            </p>
            <input
              className="demo-req-input-label"
              type="text"
              name="primary_juris_of_practice"
              placeholder="Enter your primary jurisdiction"
              value={values.primary_juris_of_practice}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p className="formik-error">
              {touched?.primary_juris_of_practice &&
                errors?.primary_juris_of_practice}
            </p>
            <input
              className="demo-req-input-label"
              type="text"
              name="email"
              placeholder="Enter your email address"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p className="formik-error">{touched?.email && errors?.email}</p>
            <Button
              type="submit"
              // data-dismiss="is_data_modal"
              onClick={handleClose}
              // disabled={isSigningUp || props.isSubmitting}
              className={cn('arbilex-button', {
                // loading: isSigningUp
              })}
            >
              {'Submit'}
            </Button>
          </form>
        )}
      </Formik>
      {/* <hr />
        <div className="helper-text">
          Have an account?&nbsp;
          <span onClick={openLoginModal} className="blue-link">
            Log in
          </span>
        </div> */}
    </div>
  );
};

export default DemoRequestModalModal;
