import React, { Component } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'

import _ from 'lodash';

import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { detect } from 'detect-browser';
import moment from 'moment';
import ResizeObserver from 'resize-observer-polyfill';

import settings from './settings';
import * as constants from './constants';
import { setStorageItem, removeStorageItem } from './helpers/storage';
import LandingContainer from './components/landing/LandingContainer';

//TODO(selberg): remove this after a couple weeks
removeStorageItem('ARBILEX__USER_ACCEPTED_COOKIES');

const App = () => {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: 'production',
  };

  function TestError() {
    const a = null;
    return a.errorCode();
  }
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <div className="app">
          <LandingContainer />
          <ToastContainer autoClose={3000} />
          {!!settings.ARBILEX__ENABLE_COOKIE_WIDGET &&
            !this.state.userAcceptedCookies && (
              <div className="cookie-consent-widget">
                <div className="cookie-flexbox">
                  <div className="cookie-text">
                    We use cookies to offer you a better experience and analyze
                    site traffic. By continuing to use this website, you consent
                    to the use of cookies in accordance with our{' '}
                    <u
                      className="privacy-policy-link"
                      onClick={this.openPrivacyPolicyModal}
                    >
                      Privacy Policy
                    </u>
                    . You can view and revoke your consent in the cookies
                    section of our{' '}
                    <u
                      className="privacy-policy-link"
                      onClick={this.openPrivacyPolicyModal}
                    >
                      Privacy Policy
                    </u>
                    .
                  </div>
                  <button onClick={this.acceptCookies}>Accept</button>
                </div>
              </div>
            )}
        </div>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
